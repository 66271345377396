import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import Seo from "../components/Seo"

let dictRusNames = [
  { rus: "Политика в области качества", eng: "Politika_v_oblasti_kachestva" },
  { rus: "Стратегические цели СВЛ", eng: "Strategicheskiye_tseli_SVL" },
]

export default function Docs({ data }) {
  return (
    <Layout>
      <Seo title="Вакансии" />
      <div className="docs-container">
        {data.allFiles.nodes
          .sort(function (a, b) {
            return a.name.length - b.name.length
          })
          .map((x) => {
            let rusName = dictRusNames.find((d) => d.eng == x.name)?.rus
            return (
              <ul
                key={`${x.id}`}
                style={{ listStyleType: "disc" }}
                className="docs-list"
              >
                <li>
                  <div>
                    <h3 style={{ fontSize: "1.1rem" }}>{rusName}</h3>
                    <div style={{ marginLeft: 7 }}>
                      (
                      <div
                        // href={`${x.publicURL}`}
                        // target="_blank"
                        onClick={() => {
                          openURI(x.publicURL, `${rusName}${x.ext}`)
                        }}
                        // href="#!"
                        className="link-docs"
                      >
                        открыть
                      </div>
                      ,
                      <div
                        onClick={() => {
                          downloadURI(x.publicURL, `${rusName}${x.ext}`)
                        }}
                        // href="#!"
                        className="link-docs"
                      >
                        скачать
                      </div>
                      )
                    </div>
                  </div>
                </li>
              </ul>
            )
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFiles: allFile(
      filter: { ext: { eq: ".pdf" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        ext
        name
        extension
        publicURL
        id
      }
    }
  }
`

function downloadURI(uri, name) {
  var link = document.createElement("a")
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function openURI(uri, name) {
  var link = document.createElement("a")
  link.target = "_blank"
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
